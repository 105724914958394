<template>
    <div class="row">
        <template v-for="usertype in this.userTypes" v-bind:key="usertype.id">
            <div v-if="usertype.position !== 1" class="col-6 mb-5">
                <div class="card mb-5 mb-xl-10">
                    <!--begin::Card header-->
                    <div
                        aria-controls="kt_account_connected_accounts"
                        aria-expanded="true"
                        class="card-header border-0 cursor-pointer"
                        data-bs-target="#kt_account_connected_accounts"
                        data-bs-toggle="collapse"
                        role="button"
                    >
                        <div class="card-title m-0">
                            <h3 class="fw-bolder m-0">{{ usertype.label }}</h3>
                        </div>
                    </div>
                    <!--end::Card header-->

                    <!--begin::Content-->
                    <div id="kt_account_connected_accounts" class="collapse show">
                        <!--begin::Card body-->
                        <div class="card-body border-top p-9">
                            <!--begin::Items-->
                            <div class="py-2">
                                <!--begin::Item-->
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-folder-open fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.dashboard.drive") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('drive') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('drive', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('drive', usertype.id)" />
                                            <label class="form-check-label" for=""></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-chart-line fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.dashboard.attendanceMonitoring") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('attendance') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('attendance', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('attendance', usertype.id)" />
                                            <label class="form-check-label" for="googleswitch"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-poll fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.dashboard.results") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('results') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('results', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('results', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-map-marked-alt fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.roadmap.title") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('roadmap') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('roadmap', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('roadmap', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-lightbulb fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.ideas.title") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('ideas_box') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('ideas_box', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('ideas_box', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-lightbulb fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.usefulTraining.title") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('useful-training') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('useful-training', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('useful-training', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-users fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.dashboard.users") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('users') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('users', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('users', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-sitemap fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">{{ $t("pages.dashboard.entities") }}</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('entities') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('entities', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('entities', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-eye fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">Enquête</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('survey') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('survey', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('survey', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator separator-dashed my-5"></div>
                                <div class="d-flex flex-stack">
                                    <div class="d-flex">
                                        <i class="fas fa-chart-pie fs-1 w-30px me-6"></i>
                                        <div class="d-flex flex-column">
                                            <a class="fs-5 text-dark text-hover-primary fw-bolder" href="#">Statistiques</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-check form-check-solid form-switch">
                                            <input
                                                v-if="usertype.services.indexOf('statistics') !== -1"
                                                checked
                                                class="form-check-input w-40px h-20px"
                                                type="checkbox"
                                                @change="switchServices('statistics', usertype.id)"
                                            />
                                            <input v-else class="form-check-input w-40px h-20px" type="checkbox" @change="switchServices('statistics', usertype.id)" />
                                            <label class="form-check-label"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Items-->
                        </div>
                        <!--end::Card body-->

                        <!--begin::Card footer-->
                        <div class="card-footer d-flex justify-content-end py-6 px-9">
                            <button class="btn btn-white btn-active-light-primary me-2">{{ $t("general.cancel") }}</button>
                            <button :ref="'submitButton' + usertype.id" class="btn btn-primary" @click="updateUserType(usertype, $refs['submitButton' + usertype.id])">
                                <span class="indicator-label"> {{ $t("general.save") }} </span>
                                <span class="indicator-progress">
                                    {{ $t("general.pleaseWait") }}
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                <span class="indicator-validate">
                                    {{ $t("general.done") }}
                                    <span class="fas fa-check f-5 ms-3"></span>
                                </span>
                            </button>
                        </div>

                        <!--end::Card footer-->
                    </div>
                    <!--end::Content-->
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import userprofileApi from "@/core/services/UserProfile";
import buttonHandle from "@/shared.js";

interface UserTypeInterface {
    id: number;
    label: string;
    position: number;
    services: Array<string>;
}

export default defineComponent({
    name: "administration-access",
    data: function () {
        return {
            userTypes: [
                {
                    id: 0,
                    label: "",
                    position: 0,
                    services: [],
                } as UserTypeInterface,
            ],
        };
    },
    methods: {
        getUserTypes() {
            userprofileApi.getUserTypes().then((response) => {
                let userTypes = response.data.usertypes;
                this.userTypes = userTypes.map((userType) => {
                    userType.services = userType.services.map((service) => {
                        return service.slug;
                    });
                    return userType;
                });
            });
        },
        switchServices(service, usertypeId) {
            this.userTypes = this.userTypes.map((usertype) => {
                if (usertype.id === usertypeId) {
                    if (usertype.services.indexOf(service) !== -1) {
                        usertype.services.splice(usertype.services.indexOf(service), 1);
                    } else {
                        usertype.services.push(service);
                    }
                }
                return usertype;
            });
        },
        updateUserType(usertype, buttonRef) {
            userprofileApi.updateUserTypes(this.userTypes);
        },
    },
    created() {
        this.getUserTypes();
    },
    setup() {
        const submitButton2 = ref<HTMLElement | null>(null);
        const submitButton3 = ref<HTMLElement | null>(null);
        onMounted(() => {
            setCurrentPageBreadcrumbs("Gestion des accès", []);
        });
        return { submitButton2, submitButton3 };
    },
});
</script>
